function wattConvertor(kw, postfix = '', level = 1) {
  while (kw >= 1000 && level <= 4) {
    kw = kw / 1000;
    level++;
  }
  let levelStr = getWattLevel(level, postfix);
  return kw.toFixed(2) + ' ' + levelStr;
}

function getWattLevel(level, postfix) {
  let str = '';
  switch (level) {
    case 1: str = 'KW'; break;
    case 2: str = 'MW'; break;
    case 3: str = 'GW'; break;
    case 4: str = 'TW'; break;
    default:
      str = 'PW';
  }
  return str + postfix;
}
module.exports = {
  wattConvertor,
  getWattLevel
}