import ApiService from "@/core/services/api.service";
import { wattConvertor } from "@/assets/js/functions/wattConvertor";

import moment from "moment/dist/moment";
import '@/assets/js/functions/moment.settings';

// action types

export const GET_STATIONS = 'getStations';
export const GET_STATION = 'getStation';
export const STORE_STATION = 'storeStation';
export const UPDATE_STATION = 'updateStation';
export const DELETE_STATION = 'deleteStation';
export const GET_ANALYZERS = 'getAnalyzers';
export const GET_ANALYZER = 'getAnalyzer';
export const GET_COUNTERS = 'getCounters';
export const GET_COUNTER = 'getCounter';
export const GET_STATION_TODAY_EXPORT = 'getStationTodayExport';
export const GET_ALL_STATIONS_TOTAL = 'getAllStationsTotal';

//
export const GET_STATION_DAILY_TOTAL = 'getStationDailyTotal';
export const GET_STATION_TOTAL = 'getStationTotal';
export const GET_STATION_AREA_DAILY = 'getStationAreaDaily';
export const GET_STATION_AREA_MONTHLY = 'getStationAreaMonthly';
export const SET_STATION_TODAY_EXPORT = 'setStationTodayExport';
export const SET_ALL_STATIONS_TOTAL = 'setAllStationsTotal';
export const RESET_STATION_DAILY_TOTAL = 'resetStationDailyTotal';
export const GET_STATION_DAILY_DATA = 'getStationDailyData';
export const GET_STATION_MONTHLY_DATA = 'getStationMonthlyData';
export const GET_STATION_INVERTER_ALARMS = 'getStationInverterAlarms';

// mutation types
export const SET_STATIONS = 'setStations';
export const SET_STATION = 'setStation';
export const SET_ANALYZERS = 'setAnalyzers';
export const SET_ANALYZER = 'setAnalyzer';
export const SET_COUNTERS = 'setCounters';
export const SET_COUNTER = 'setCounter';
//
export const SET_STATION_DAILY_TOTAL = 'getStationDailyTotal';
export const SET_STATION_TOTAL = 'getStationTotal';
export const SET_STATION_AREA_DAILY = 'getStationAreaDaily';
export const SET_STATION_AREA_MONTHLY = 'getStationAreaMonthly';

export const SET_ERROR = "setError";
export const SET_STATIONS_TABLE_LOADING = "setStationsTableLoading";
export const RESET_STATION_DAILY_TOTAL_MUTATION = 'resetStationDailyTotalMutation';

export const GET_STATION_DAILY_DATA_MUTATION = 'getStationDailyDataMutation';
export const GET_STATION_MONTHLY_DATA_MUTATION = 'getStationMonthlyDataMutation';
export const SET_STATION_INVERTER_ALARMS = 'setStationInverterAlarms';

const state = {
  errors: null,
  stationsTableLoading: true,
  stations: [],
  station: [],
  analyzers: [],
  analyzer: null,
  counters: [],
  counter: null,
  stationDailyTotal: 0,
  stationTotal: 0,
  stationDaily: [],
  stationMonthly: [],
  stationSeries: [],
  stationCategories: [],
  stationTodayExport: [],
  allStationsTotal: []

};

const getters = {
  getStationsTableLoading: state => state.stationsTableLoading,
  getStations: state => state.stations,
  getStation: state => state.station,
  getStationById: (state) => (id) => {
    return state.stations.find(todo => todo.id === id)
  },
  getAnalyzers: state => state.analyzers,
  getAnalyzer: state => state.analyzer,
  getCounters: state => state.counters,
  getCounter: state => state.counter,
  getStationDailyTotal: state => state.stationDailyTotal,
  getStationTotal: state => state.stationTotal,
  getStationDaily: state => state.stationDaily,
  getStationMonthly: state => state.stationMonthly,
  getStationSeries: state => state.stationSeries,
  getStationCategories: state => state.stationCategories,
  getStationTodayExport: state => state.stationTodayExport,
  getAllStationsTotal: state => state.allStationsTotal
};

const actions = {
  [GET_STATIONS](context) {
    return new Promise(resolve => {
      context.commit(SET_STATIONS_TABLE_LOADING, true);
      ApiService.get("ges/powerStations")
        .then(({ data }) => {
          context.commit(SET_STATIONS, data.data);
          context.commit(SET_STATIONS_TABLE_LOADING, false);
          resolve(data.data);
        })
        .catch(({ response }) => {
          console.log(response);
          // mutationslar da sorun olabilir
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_STATION](context, payload) {
    return new Promise(resolve => {
      context.commit(SET_STATIONS_TABLE_LOADING, true);
      ApiService.get("ges/powerStations/" + payload)
        .then(({ data }) => {
          context.commit(SET_STATION, data.data);
          context.commit(SET_STATIONS_TABLE_LOADING, false);
          resolve(data.data);
        })
        .catch(({ response }) => {
          console.log(response);
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_STATION_DAILY_DATA](context, payload) {
    return new Promise(resolve => {
      ApiService.get("/ges/powerStationDailyTotal/" + payload.id + '?begin_date=' + payload.begin_date)
        .then(({ data }) => {
          context.commit(GET_STATION_DAILY_DATA_MUTATION, { response: data.data, begin_date: payload.begin_date });
          resolve(data.data);
        })
        .catch(({ response }) => {
          console.log(response);
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_STATION_MONTHLY_DATA](context, payload) {
    return new Promise(resolve => {
      ApiService.get("/ges/powerStationMonthlyTotal/" + payload.id + '?begin_date=' + payload.begin_date)
        .then(({ data }) => {
          context.commit(GET_STATION_MONTHLY_DATA_MUTATION, { response: data.data, begin_date: payload.begin_date });
          resolve(data.data);
        })
        .catch(({ response }) => {
          console.log(response);
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [STORE_STATION](context, payload) {
    return new Promise(resolve => {
      ApiService.post("ges/powerStations", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [UPDATE_STATION](context, payload) {
    return new Promise(resolve => {
      ApiService.put("ges/powerStations/" + payload.id, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [DELETE_STATION](context, payload) {
    return new Promise(resolve => {
      ApiService.delete("ges/powerStations/" + payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_ANALYZERS](context, searchText) {
    return new Promise(resolve => {
      ApiService.get("analyzers")
        .then(({ data }) => {
          context.commit(SET_ANALYZERS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_ANALYZER](context, payload) {
    return new Promise(resolve => {
      ApiService.get("analyzers/" + payload.id)
        .then(({ data }) => {
          context.commit(SET_ANALYZER, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_COUNTERS](context, searchText) {
    return new Promise(resolve => {
      ApiService.get("meters")
        .then(({ data }) => {
          context.commit(SET_COUNTERS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [GET_COUNTER](context, payload) {
    return new Promise(resolve => {
      ApiService.get("meters/" + payload.id)
        .then(({ data }) => {
          context.commit(SET_COUNTER, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_STATION_DAILY_TOTAL](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      // let path = payload.id + '?begin_date=2021-06-12';
      ApiService.get("ges/powerStationDailyTotal", path)
        .then(({ data }) => {
          context.commit(SET_STATION_DAILY_TOTAL, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_STATION_TOTAL](context, payload) {
    return new Promise(resolve => {
      let path = payload.id;
      ApiService.get("ges/powerStationTotal", path)
        .then(({ data }) => {
          context.commit(SET_STATION_TOTAL, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_STATION_AREA_DAILY](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      ApiService.get("ges/stationFieldBasedDailyTotal", path)
        .then(({ data }) => {
          context.commit(SET_STATION_AREA_DAILY, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_STATION_AREA_MONTHLY](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      ApiService.get("ges/stationFieldBasedMonthlyTotal", path)
        .then(({ data }) => {
          context.commit(SET_STATION_AREA_MONTHLY, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_STATION_TODAY_EXPORT](context, payload) {
    return new Promise(resolve => {
      let path = "/ges/allPowerStationDailyTotal";
      ApiService.get(path)
        .then(({ data }) => {
          context.commit(SET_STATION_TODAY_EXPORT, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },

  [GET_ALL_STATIONS_TOTAL](context, payload) {
    return new Promise(resolve => {
      let path = "/ges/allPowerStationTotal";
      ApiService.get(path)
        .then(({ data }) => {
          context.commit(SET_ALL_STATIONS_TOTAL, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [RESET_STATION_DAILY_TOTAL](context, payload) {
    return new Promise(resolve => {
      context.commit(RESET_STATION_DAILY_TOTAL_MUTATION);
    });
  },
  //Santrale Ait İnvertör Alarmlarını Getirir
  [GET_STATION_INVERTER_ALARMS](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date + '&end_date=' + payload.end_date;
      ApiService.get("ges/powerStationsInverterAlarms", path)
        .then(({ data }) => {
          context.commit(SET_STATION_INVERTER_ALARMS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        })
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_STATIONS_TABLE_LOADING](state, status) {
    state.stationsTableLoading = status;
  },
  [SET_STATIONS](state, response) {
    response.forEach(el => {
      el.dailyTotalConvert = wattConvertor(el.dailyTotal,'h');
      // el.acTotalPower = wattConvertor(el.acTotalPower);
      // el.dcTotalPower = wattConvertor(el.dcTotalPower);
    })
    state.stations = response;
  },
  [SET_STATION](state, response) {
    state.station = response;
  },
  [SET_ANALYZERS](state, response) {
    state.analyzers = response;
  },
  [SET_COUNTERS](state, response) {
    state.counters = response;
  },
  [SET_ANALYZER](state, response) {
    state.analyzer = response;
  },
  [SET_COUNTER](state, response) {
    state.counter = response;
  },
  [SET_STATION_DAILY_TOTAL](state, response) {
    if (response.length > 0) {
      let stationDailyTotal = false;
      response.forEach(el=>{
        let today = moment().format('YYYY-MM-DD');
        if(el.date_entry == today){
          state.stationDailyTotal = wattConvertor(el.total, 'h');
          stationDailyTotal = true;
        }
      })
      if(!stationDailyTotal){
        state.stationDailyTotal = wattConvertor(0, 'h');
      }
    }
    else {
      state.stationDailyTotal = wattConvertor(0, 'h');
    }
  },
  [SET_STATION_TOTAL](state, response) {
    state.stationTotal = wattConvertor(response, 'h');
  },
  [SET_STATION_AREA_DAILY](state, response) {
    state.stationSeries = [];
    state.stationCategories = [];
    response.forEach((el) => {
      el.total_convert = wattConvertor(el.total, 'h');
      state.stationSeries.push(el.total);
      state.stationCategories.push(el.name);
    });
    state.stationDaily = response;
  },
  [SET_STATION_AREA_MONTHLY](state, response) {
    state.stationSeries = [];
    state.stationCategories = [];
    response.forEach((el) => {
      el.total_convert = wattConvertor(el.total, 'h');
      state.stationSeries.push(el.total);
      state.stationCategories.push(el.name);
    });
    state.stationMonthly = response;
  },
  [SET_STATION_TODAY_EXPORT](state, response) {
    state.stationTodayExport = wattConvertor(response, 'h');
  },
  [SET_ALL_STATIONS_TOTAL](state, response) {
    state.allStationsTotal = wattConvertor(response, 'h');
  },
  [RESET_STATION_DAILY_TOTAL_MUTATION]() {
    state.stationDailyTotal = 0;
  },
  [GET_STATION_DAILY_DATA_MUTATION](state, { response, begin_date }) {
    response.forEach(item => {
      item.totalConvert = wattConvertor(item.total, 'h');
      item.dateConvert = moment(item.date_entry).format('DD.MM.YYYY');
      item.timestamp = moment(item.date_entry).format('X');
      item.timestampx = moment(item.date_entry).format('x') * 1;
    });
    let j = 0;
    let max_day = 0;
    let current_year = moment().format('YYYY');
    let current_month = moment().format('MM');
    let response_year = moment(begin_date).format('YYYY');
    let response_month = moment(begin_date).format('MM');
    if (response_year == current_year) {//Getirilen veri yılı şimdiki yılsa doldurulacak maksimum günü belirlemek gerekir
      if (response_month == current_month) {//Eğer mevcut ay üzerinden veri getiriliyorsa bu güne kadar ki veriler dolurulacak
        max_day = moment().format('DD') * 1;
      }
      else {
        max_day = moment(begin_date).endOf('month').format('DD') * 1;
      }
    }
    else {//Getirilen veri yılı mevcut yıldan azsa doldurulacak maksimum gün o ayın maksimum günü kadardır
      max_day = moment(begin_date).endOf('month').format('DD') * 1;
    }
    let data_day = '';
    for (let i = 0; i < max_day; i++) {
      let temp_day = (i < 9) ? '0' + (i + 1) : '' + (i + 1);
      if (response[j]) {
        data_day = moment(response[j].date_entry).format('DD');
      }
      else {
        data_day = moment().format('DD');
      }
      if (temp_day != data_day) { //Eğer eksik değer varsa boş veri ekliyoruz
        let temp_date = response_year + '-' + response_month + '-' + temp_day;
        response.push({
          total: 0,
          totalConvert: '---',
          date_entry: temp_date,
          dateConvert: moment(temp_date).format('DD.MM.YYYY'),
          timestamp: moment(temp_date).format('X'),
          timestampx: moment(temp_date).format('x') * 1,
        });
      }
      else {
        j++;
      }
    }
    response.sort((a, b) => {
      let a_day = moment(a.date_entry).format('DD') * 1;
      let b_day = moment(b.date_entry).format('DD') * 1;
      return (a_day > b_day) ? 1 : -1;
    });
  },
  [GET_STATION_MONTHLY_DATA_MUTATION](state, { response, begin_date }) {
    response.forEach(item => {
      item.totalConvert = wattConvertor(item.total, 'h');
      item.dateConvert = moment(item.date_entry).format('MMMM YYYY');
      item.timestamp = moment(item.date_entry).format('X');
      item.timestampx = moment(item.date_entry).format('x') * 1;
    });
    response.reverse();
    let j = 0;
    let max_month = 0;
    let current_year = moment().format('YYYY');
    let response_year = moment(begin_date).format('YYYY');
    if (response_year == current_year) {//Getirilen veri yılı şimdiki yılsa doldurulacak maksimum ay mevcut aydır
      max_month = moment().format('MM') * 1;
    }
    else {//Getirilen veri yılı mevcut yıldan azsa doldurulacak maksimum ay 12 dir
      max_month = 12;
    }
    let data_month = '';
    for (let i = 0; i < max_month; i++) {
      let temp_month = (i < 9) ? '0' + (i + 1) : '' + (i + 1);
      if (response[j]) {
        data_month = moment(response[j].date_entry).format('MM');
      }
      else {
        data_month = moment().format('MM');
      }
      if (temp_month != data_month) { //Eğer eksik değer varsa boş veri ekliyoruz
        let temp_date = response_year + '-' + temp_month;
        response.push({
          total: 0,
          totalConvert: '---',
          date_entry: temp_date,
          dateConvert: moment(temp_date).format('MMMM YYYY'),
          timestamp: moment(temp_date).format('X'),
          timestampx: moment(temp_date).format('x') * 1,
        });
      }
      else {
        j++;
      }
    }
    response.sort((a, b) => {
      let a_month = moment(a.date_entry).format('MM') * 1;
      let b_month = moment(b.date_entry).format('MM') * 1;
      return (a_month > b_month) ? 1 : -1;
    });
  },
  [SET_STATION_INVERTER_ALARMS](state, response) {
    response.forEach(item => {
      item.dateConvert = moment(item.date_record).format('DD.MM.YYYY HH:mm');
      item.timestamp = moment(item.date_record).format('X');
      item.info.forEach((infoItem,index) => {
        item.info[index] = infoItem.replaceAll('"', ' ');
      });
    });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
