import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import store from './store';
import { LOGOUT } from "./store/auth.module";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
    init() {
      Vue.use(VueAxios, axios);
      //location.protocol http: veya https: döner
      Vue.axios.defaults.baseURL = location.protocol+'//'+process.env.VUE_APP_API_HOST+'/';
    },

    /**
     * Set the default HTTP request headers
     */
    setHeader() {
      Vue.axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
    },

    query(resource, params) {
      return Vue.axios.get(resource, params).catch(error => {
        throw new Error(`[KT] ApiService ${error}`);
      });
    },

    /**
     * Send the GET HTTP request
     * @param resource
     * @param slug
     * @returns {*}
     */
    get(resource, slug = "") {
      //$cookies.set('denemeGET','deneme','1min');
      if (slug != "") {
        return Vue.axios.get(`${resource}/${slug}`).catch(error => {
          throw new Error(`[KT] ApiService ${error}`);
        });
      } else {
        return Vue.axios.get(`${resource}`).catch(error => {
          throw new Error(`[KT] ApiService ${error}`);
        });
      }
    },

    /**
     * Set the POST HTTP request
     * @param resource
     * @param params
     * @returns {*}
     */
    post(resource, params) {
      let res =  Vue.axios.post(`${resource}`, params);
      return res;
    },

    /**
     * Send the UPDATE HTTP request
     * @param resource
     * @param slug
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },

    /**
     * Send the PUT HTTP request
     * @param resource
     * @param params
     * @returns {IDBRequest<IDBValidKey> | Promise<void>}
     */
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },

    /**
     * Send the DELETE HTTP request
     * @param resource
     * @returns {*}
     */
    delete(resource) {
        return Vue.axios.delete(resource).catch(error => {
          throw new Error(`[RWV] ApiService ${error}`);
        });
    },

};

axios.interceptors.response.use(
  function(response){
    return response;
  },
  function(error){
    switch (error.response.status) {
      case 401://401 Hatası Geldiği Zaman Mevcut Oturumu Kapatıp Login Sayfasına Yönlendirir
        store.dispatch(LOGOUT);
      break;
    }
    return Promise.reject(error);//Hatayı module içerisinde kullanmak için geri döndürmemiz gerekiyor
  }
);


export default ApiService;
