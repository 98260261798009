import Vue from "vue";
import Router from "vue-router";
import store from "@/core/services/store/index";

Vue.use(Router);

const checkRole = (to,from,next)=>{
  let userRole = store.getters.getUserRole;
  if(userRole == 'admin') {
    next();
  } else {
    next({name:'dashboard'});
  }
};

const checkAuth = (to,from,next) => {
  let auth = store.getters.isAuthenticated;
  if(auth){
    return next({ name:'dashboard' });
  }
  else{
    return next({ name:'login' });
  }
}

const checkAuthLogin = (to,from,next) => {
  let auth = store.getters.isAuthenticated;
  if(auth){
    return next({ name:'dashboard' });
  }
  else{
    return next();
  }
}

export default new Router({
  routes: [
    {
      path: '/',
      name: 'root',
      beforeEnter:checkAuth
    },
    {
      path: '/dashboard',
      component: () => import("@/view/layout/Layout"),
      children:[
        {
          path:'/dashboard',
          name: 'dashboard',
          component: () => import("@/view/project/dashboard/Main.vue")
        }
      ]
    },
    {
      path: "/detail",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: '/detail',
          name: 'detail',
          component: () => import("@/view/project/Detail.vue")
        }
      ]
    },
      //Areas
    {
      path: "/areas",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: '/areas',
          name: "areas",
          component: () => import("@/view/project/areas/Index")
        },
        {
          path: "/areas/show/:id",
          name: "areas.show",
          component: () => import("@/view/project/areas/Show")
        },
        {
          path: "/areas/create",
          name: "areas.create",
          component: () => import("@/view/project/areas/Create.vue")
        },
        {
          path: "/areas/edit/:id",
          name: "areas.edit",
          component: () => import("@/view/project/areas/Edit.vue")
        }
      ]
    },
      //Stations
    {
      path: "/stations",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: '/stations',
          name: "stations",
          component: () => import("@/view/project/stations/Index")
        },
        {
          path: "/stations/show/:id",
          name: "stations.show",
          component: () => import("@/view/project/stations/Show")
        },
        {
          path: "/stations/create",
          name: "stations.create",
          component: () => import("@/view/project/stations/Create")
        },
        {
          path: "/stations/edit/:id",
          name: "stations.edit",
          component: () => import("@/view/project/stations/Edit")
        }
      ]
    },
      //Station Types
    {
      path: "/station-types",
      beforeEnter: checkRole,
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: '/station-types',
          name: "station-types",
          component: () => import("@/view/project/stationTypes/Index")
        },
        {
          path: "/station-types/create",
          name: "station-types.create",
          component: () => import("@/view/project/stationTypes/Create.vue")
        },
        {
          path: "/station-types/edit/:id",
          name: "station-types.edit",
          component: () => import("@/view/project/stationTypes/Edit.vue")
        }
      ]
    },
      //Station Auth
    {
      path: "/station-types",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: '/station-types',
          name: "station-auth",
          component: () => import("@/view/project/stationAuth/Index")
        }
      ]
    },
      //Inverters
    {
      path: "/inverters",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: '/inverters',
          name: "inverters",
          component: () => import("@/view/project/inverters/Index")
        },
        {
          path: "/inverters/show/:id",
          name: "inverters.show",
          component: () => import("@/view/project/inverters/Show")
        },
        {
          path: "/inverters/create",
          name: "inverters.create",
          component: () => import("@/view/project/inverters/Create.vue")
        },
        {
          path: "/inverters/edit/:id",
          name: "inverters.edit",
          component: () => import("@/view/project/inverters/Edit.vue")
        }
      ]
    },
      //Inverter Types
    {
      path: "/inverter-types",
      beforeEnter: checkRole,
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: '/inverter-types',
          name: "inverter-types",
          component: () => import("@/view/project/inverterTypes/Index")
        },
        {
          path: "/inverter-types/create",
          name: "inverter-types.create",
          component: () => import("@/view/project/inverterTypes/Create.vue")
        },
        {
          path: "/inverter-types/edit/:id",
          name: "inverter-types.edit",
          component: () => import("@/view/project/inverterTypes/Edit.vue")
        }
      ]
    },
    // Profile
    {
          path: "/profile",
          component: () => import("@/view/layout/Layout"),
          children: [
              {
                  path: '/profile',
                  name: "profile",
                  component: () => import("@/view/pages/profile/Profile-1")
              },
          ]
      },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/login",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      beforeEnter: checkAuthLogin,
      children:[
        {
          path:'/login',
          name: 'login',
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          path:'/register',
          name: 'register',
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ],
  mode: "history"
});
