// action types
import JwtService from "@/core/services/jwt.service";

export const GET_STATION_TYPES = 'getStationTypes';
export const GET_STATION_TYPE = 'getStationType';
export const STORE_STATION_TYPE = 'storeStationType';
export const UPDATE_STATION_TYPE = 'updateStationType';
export const DELETE_STATION_TYPE = 'deleteStationType';

// mutation types
export const SET_STATION_TYPES = 'setStationTypes';
export const SET_STATION_TYPE = 'setStationType';

export const SET_ERROR = "setError";
export const SET_STATION_TYPES_TABLE_LOADING = "setStationTypesTableLoading";

import ApiService from "@/core/services/api.service";

const state = {
    errors: null,
    stationTypesTableLoading: true,
    stationTypes: [],
    stationType: []
};

const getters = {
    getStationTypesTableLoading: state => state.stationTypesTableLoading,
    getStationTypes: state => state.stationTypes,
    getStationType: state => state.stationType,
};

const actions = {
    [GET_STATION_TYPES](context) {
        return new Promise(resolve => {
            context.commit(SET_STATION_TYPES_TABLE_LOADING,true);
            ApiService.get("ges/powerStationTypes")
                .then(({data}) => {
                    context.commit(SET_STATION_TYPES, data.data);
                    resolve(data.data);
                    context.commit(SET_STATION_TYPES_TABLE_LOADING,false);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [GET_STATION_TYPE](context,payload) {
        return new Promise(resolve => {
            ApiService.get("ges/powerStationTypes/"+payload)
                .then(({data}) => {
                    context.commit(SET_STATION_TYPE, data.data);
                    resolve(data.data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [STORE_STATION_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.post("ges/powerStationTypes",payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [UPDATE_STATION_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.put("ges/powerStationTypes/"+payload.id,payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [DELETE_STATION_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.delete("ges/powerStationTypes/"+payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_STATION_TYPES_TABLE_LOADING](state, status) {
        state.stationTypesTableLoading = status;
    },
    [SET_STATION_TYPES](state, response) {
        state.stationTypes = response;
    },
    [SET_STATION_TYPE](state, response) {
        state.stationType = response;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
