import ApiService from "@/core/services/api.service";
import { wattConvertor } from '@/assets/js/functions/wattConvertor';

import moment from "moment/dist/moment";
import '@/assets/js/functions/moment.settings';

// action types

export const GET_INVERTERS = 'getInverters';
export const GET_INVERTER = 'getInverter';
export const STORE_INVERTER = 'storeInverter';
export const UPDATE_INVERTER = 'updateInverter';
export const DELETE_INVERTER = 'deleteInverter';
export const GET_DATA_LOGGERS = 'getDataLoggers';
//
export const GET_DAILY_INVERTER_DATA = 'getDailyInverterData';
export const GET_HOURLY_INVERTER_DATA = 'getHourlyInverterData';
export const GET_MONTHLY_INVERTER_DATA = 'getMonthlyInverterData';
export const GET_TOTAL_INVERTER_DATA = 'getTotalInverterData';
export const GET_INVERTER_DATAS = 'getInverterDatas';
//İnvertör Alarmlarını Getirir
export const GET_INVERTER_ALARMS = 'getInverterAlarms';

//İnverter Verilerini Sıfırlama
export const REFRESH_INVERTER_DATAS = 'refreshInverterDatas';

//Günün Üretimi Sıfırlama
export const REFRESH_DAILY_INVERTER_DATA = 'refreshDailyInverterDatas';

//Toplam Üretimi Sıfırlama
export const REFRESH_TOTAL_INVERTER_DATA = 'refreshTotalInverterDatas';

// mutation types
export const SET_INVERTERS = 'setInverters';
export const SET_INVERTER = 'setInverter';
export const SET_ERROR = "setError";
export const SET_INVERTERS_TABLE_LOADING = "setInvertersTableLoading";
export const SET_DATA_LOGGERS = 'setDataLoggers';
//
export const SET_DAILY_INVERTER_DATA = 'setDailyInverterData';
export const SET_HOURLY_INVERTER_DATA = 'setHourlyInverterData';
export const SET_MONTHLY_INVERTER_DATA = 'setMonthlyInverterData';
export const SET_TOTAL_INVERTER_DATA = 'setTotalInverterData';
export const SET_INVERTER_DATAS = 'setInverterDatas';
export const SET_INVERTER_DATAS_CHART = 'setInverterDatasChart';

//İnverter Verilerini Sıfırlama Mutation
export const REFRESH_INVERTER_DATAS_MUTATION = 'refreshInverterDatasMutation';

//Günün Üretimi Sıfırlama Mutation
export const REFRESH_DAILY_INVERTER_DATA_MUTATION = 'refreshDailyInverterDatasMutation';

//Toplam Üretimi Sıfırlama Mutation
export const REFRESH_TOTAL_INVERTER_DATA_MUTATION = 'refreshTotalInverterDatasMutation';

//İnvertör Alarmlarını Tanımlar
export const SET_INVERTER_ALARMS = 'setInverterAlarms';

const state = {
  errors: null,
  invertersTableLoading: true,
  inverters: [],
  inverter: [],
  dataLoggers: [],
  hourlyInverterData: 0,
  dailyInverterData: 0,
  monthlyInverterData: 0,
  totalInverterData: 0,
  inverterSeries: [],
  inverterCategories: [],
  inverterData: [],
  inverterDatas: [],
  inverterDatasCategories: [],
  inverterDatasSeries: [],
};

const getters = {
  getInvertersTableLoading: state => state.invertersTableLoading,
  getInverters: state => state.inverters,
  getInverter: state => state.inverter,
  getDataLoggers: state => state.dataLoggers,
  getHourlyInverterData: state => state.hourlyInverterData,
  getDailyInverterData: state => state.dailyInverterData,
  getMonthlyInverterData: state => state.monthlyInverterData,
  getTotalInverterData: state => state.totalInverterData,
  getInverterSeries: state => state.inverterSeries,
  getInverterCategories: state => state.inverterCategories,
  getInverterData: state => state.inverterData,
  getInverterDatas: state => state.inverterDatas,
  getInverterDatasCategories: state => state.inverterDatasCategories,
  getInverterDatasSeries: state => state.inverterDatasSeries,
};

const actions = {
  [GET_INVERTERS](context) {
    return new Promise(resolve => {
      context.commit(SET_INVERTERS_TABLE_LOADING, true);
      ApiService.get("ges/inverters")
        .then(({ data }) => {
          context.commit(SET_INVERTERS, data.data);
          context.commit(SET_INVERTERS_TABLE_LOADING, false);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_INVERTER](context, id) {
    return new Promise(resolve => {
      ApiService.get("ges/inverters/" + id)
        .then(({ data }) => {
          context.commit(SET_INVERTER, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [STORE_INVERTER](context, payload) {
    return new Promise(resolve => {
      ApiService.post("ges/inverters", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_INVERTER](context, payload) {
    return new Promise(resolve => {
      ApiService.put("ges/inverters/" + payload.id, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [DELETE_INVERTER](context, payload) {
    return new Promise(resolve => {
      ApiService.delete("ges/inverters/" + payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_DATA_LOGGERS](context, payload) {
    return new Promise(resolve => {
      ApiService.get("commdevice/" + payload.id)
        .then(({ data }) => {
          context.commit(SET_DATA_LOGGERS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_HOURLY_INVERTER_DATA](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      ApiService.get("ges/inverterExportHourly", path)
        .then(({ data }) => {
          context.commit(SET_HOURLY_INVERTER_DATA, { response: data.data, begin_date: payload.begin_date });
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_DAILY_INVERTER_DATA](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      ApiService.get("ges/inverterExportDaily", path)
        .then(({ data }) => {
          context.commit(SET_DAILY_INVERTER_DATA, { response: data.data, begin_date: payload.begin_date });
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_MONTHLY_INVERTER_DATA](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      ApiService.get("ges/inverterExportMonthly", path)
        .then(({ data }) => {
          context.commit(SET_MONTHLY_INVERTER_DATA, { response: data.data, begin_date: payload.begin_date });
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_TOTAL_INVERTER_DATA](context, payload) {
    return new Promise(resolve => {
      let path = payload.id;
      ApiService.get("ges/inverterExportTotal", path)
        .then(({ data }) => {
          context.commit(SET_TOTAL_INVERTER_DATA, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_INVERTER_DATAS](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      ApiService.get("ges/inverterDatas", path)
        .then(({ data }) => {
          context.commit(SET_INVERTER_DATAS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [REFRESH_INVERTER_DATAS](context) {
    return context.commit(REFRESH_INVERTER_DATAS_MUTATION);
  },
  [REFRESH_DAILY_INVERTER_DATA](context) {
    return context.commit(REFRESH_DAILY_INVERTER_DATA_MUTATION);
  },
  [REFRESH_TOTAL_INVERTER_DATA](context) {
    return context.commit(REFRESH_TOTAL_INVERTER_DATA_MUTATION);
  },
  //İnvertöre Ait Alarmları Getirir
  [GET_INVERTER_ALARMS](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date + '&end_date=' + payload.end_date;
      ApiService.get("ges/inverterAlarms", path)
        .then(({ data }) => {
          context.commit(SET_INVERTER_ALARMS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        })
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_INVERTERS_TABLE_LOADING](state, status) {
    state.invertersTableLoading = status;
  },
  [SET_INVERTERS](state, response) {
    response.forEach(el => {
      if (el.last_data_time != null) {
        el.dateConvert = moment(el.last_data_time).format('DD.MM.YYYY HH:mm');
        el.timestamp = moment(el.last_data_time).format('X');
      }
      else {
        el.dateConvert = '---';
        el.timestamp = 0;
      }
    })
    state.inverters = response;
  },
  [SET_INVERTER](state, response) {
    state.inverter = response;
  },
  [SET_DATA_LOGGERS](state, response) {
    state.dataLoggers = response;
  },
  [SET_HOURLY_INVERTER_DATA](state, { response, begin_date }) {
    response.forEach(el => {
      el.exportConvert = wattConvertor(el.export, 'h');
      el.dateConvert = moment(el.date_entry).format('HH:mm');
      el.timestamp = moment(el.date_entry).format('X');
      el.timestampx = moment(el.date_entry).format('x') * 1;
    });
    let j = 0;
    let max_hour = 0;
    let current_year = moment().format('YYYY');
    let current_month = moment().format('MM');
    let current_day = moment().format('DD');
    let response_year = begin_date.split('.')[2];
    let response_month = begin_date.split('.')[1];
    let response_day = begin_date.split('.')[0];
    if (response_year == current_year) {//Eğer bugünün verisi getiriliyorsa maksimum saati belirlemek gerekir
      if (response_month == current_month) {
        if (response_day == current_day) {
          max_hour = (moment().format('HH') * 1) + 1;
        }
        else {
          max_hour = 24;
        }
      }
      else {
        max_hour = 24;
      }
    }
    else {//Getirilen veri yılı mevcut yıldan azsa doldurulacak maksimum gün o ayın maksimum günü kadardır
      max_hour = 24;
    }
    let data_hour = '';
    for (let i = 0; i < max_hour; i++) {
      let temp_hour = (i < 10) ? '0' + i : '' + i;
      if (response[j]) {
        data_hour = moment(response[j].date_entry).format('HH');
      }
      else {
        data_hour = moment().format('HH');
      }
      if (temp_hour != data_hour) { //Eğer eksik değer varsa boş veri ekliyoruz
        let temp_date = response_year + '-' + response_month + '-' + response_day + 'T' + temp_hour + ':00';
        response.push({
          export: 0,
          exportConvert: '---',
          date_entry: temp_date,
          dateConvert: moment(temp_date).format('HH:mm'),
          timestamp: moment(temp_date).format('X'),
          timestampx: moment(temp_date).format('x') * 1,
        });
      }
      else {
        j++;
      }
    }
    response.sort((a, b) => {
      let a_hour = moment(a.date_entry).format('HH') * 1;
      let b_hour = moment(b.date_entry).format('HH') * 1;
      return (a_hour > b_hour) ? 1 : -1;
    });
  },
  [SET_DAILY_INVERTER_DATA](state, { response, begin_date }) {
    if (response.length > 0) {
      let dailyInverterData = false;
      response.forEach(el => {
        el.exportConvert = wattConvertor(el.export, 'h');
        el.dateConvert = moment(el.date_entry).format('DD.MM.YYYY');
        el.timestamp = moment(el.date_entry).format('X');
        el.timestampx = moment(el.date_entry).format('x') * 1;

        let today = moment().format('YYYY-MM-DD');
        if (el.date_entry == today) {
          state.dailyInverterData = wattConvertor(el.export, 'h');
          dailyInverterData = true;
        }
      });
      if (!dailyInverterData) {
        state.dailyInverterData = wattConvertor(0, 'h');
      }
    }
    else {
      state.dailyInverterData = wattConvertor(0, 'h');
    }
    let j = 0;
    let max_day = 0;
    let current_year = moment().format('YYYY');
    let current_month = moment().format('MM');
    let response_year = moment(begin_date).format('YYYY');
    let response_month = moment(begin_date).format('MM');
    if (response_year == current_year) {//Getirilen veri yılı şimdiki yılsa doldurulacak maksimum günü belirlemek gerekir
      if (response_month == current_month) {//Eğer mevcut ay üzerinden veri getiriliyorsa bu güne kadar ki veriler dolurulacak
        max_day = moment().format('DD') * 1;
      }
      else {
        max_day = moment(begin_date).endOf('month').format('DD') * 1;
      }
    }
    else {//Getirilen veri yılı mevcut yıldan azsa doldurulacak maksimum gün o ayın maksimum günü kadardır
      max_day = moment(begin_date).endOf('month').format('DD') * 1;
    }
    let data_day = '';
    for (let i = 0; i < max_day; i++) {
      let temp_day = (i < 9) ? '0' + (i + 1) : '' + (i + 1);
      if (response[j]) {
        data_day = moment(response[j].date_entry).format('DD');
      }
      else {
        data_day = moment().format('DD');
      }
      if (temp_day != data_day) { //Eğer eksik değer varsa boş veri ekliyoruz
        let temp_date = response_year + '-' + response_month + '-' + temp_day;
        response.push({
          export: 0,
          exportConvert: '---',
          date_entry: temp_date,
          dateConvert: moment(temp_date).format('DD.MM.YYYY'),
          timestamp: moment(temp_date).format('X'),
          timestampx: moment(temp_date).format('x') * 1,
        });
      }
      else {
        j++;
      }
    }
    response.sort((a, b) => {
      let a_day = moment(a.date_entry).format('DD') * 1;
      let b_day = moment(b.date_entry).format('DD') * 1;
      return (a_day > b_day) ? 1 : -1;
    });
  },
  [SET_MONTHLY_INVERTER_DATA](state, { response, begin_date }) {
    response.forEach(el => {
      if (el.export == '---') {
        el.export = 0;
        el.exportConvert = '---';
      }
      else if (el.export == 0) {
        el.exportConvert = '---';
      }
      else {
        el.exportConvert = wattConvertor(el.export, 'h');
      }
      el.dateConvert = moment(el.date_entry).format('MMMM YYYY');
      el.timestamp = moment(el.date_entry).format('X');
      el.timestampx = moment(el.date_entry).format('x') * 1;
    });
    let j = 0;
    let max_month = 0;
    let current_year = moment().format('YYYY');
    let response_year = moment(begin_date).format('YYYY');
    if (response_year == current_year) {//Getirilen veri yılı şimdiki yılsa doldurulacak maksimum ay mevcut aydır
      max_month = moment().format('MM') * 1;
    }
    else {//Getirilen veri yılı mevcut yıldan azsa doldurulacak maksimum ay 12 dir
      max_month = 12;
    }
    let data_month = '';
    for (let i = 0; i < max_month; i++) {
      let temp_month = (i < 9) ? '0' + (i + 1) : '' + (i + 1);
      if (response[j]) {
        data_month = moment(response[j].date_entry).format('MM');
      }
      else {
        data_month = moment().format('MM');
      }
      if (temp_month != data_month) { //Eğer eksik değer varsa boş veri ekliyoruz
        let temp_date = response_year + '-' + temp_month;
        response.push({
          export: 0,
          exportConvert: '---',
          date_entry: temp_date,
          dateConvert: moment(temp_date).format('MMMM YYYY'),
          timestamp: moment(temp_date).format('X'),
          timestampx: moment(temp_date).format('x') * 1,
        });
      }
      else {
        j++;
      }
    }
    response.sort((a, b) => {
      let a_month = moment(a.date_entry).format('MM') * 1;
      let b_month = moment(b.date_entry).format('MM') * 1;
      return (a_month > b_month) ? 1 : -1;
    });
  },
  [SET_TOTAL_INVERTER_DATA](state, response) {
    state.totalInverterData = wattConvertor(response.export, 'h');
  },
  [SET_INVERTER_DATAS_CHART](state, response) {

  },
  [SET_INVERTER_DATAS](state, response) {
    const currentFixed = 2;
    const voltageFixed = 2;
    const activeFixed = 2;
    const powerFixed = 2;
    let data = [];
    response.forEach((el) => {

      el.dateConvert = moment(el.date_entry).format('DD.MM.YYYY HH:mm');
      el.timestamp = moment(el.date_entry).format('X');
      el.timestampx = moment(el.date_entry).format('x') * 1;

      el.dc_voltage_array = el.dc_voltage.split(';');
      el.dc_voltage_array.forEach((item, index, array) => {
        let temp_value = parseFloat(item);
        array[index] = temp_value.toFixed(voltageFixed);
      });

      el.ac_current_L1 = el.ac_current_L1.toFixed(currentFixed);
      el.ac_current_L2 = el.ac_current_L2.toFixed(currentFixed);
      el.ac_current_L3 = el.ac_current_L3.toFixed(currentFixed);
      el.ac_current_total = el.ac_current_total.toFixed(currentFixed);

      el.ac_voltage_L1 = el.ac_voltage_L1.toFixed(voltageFixed);
      el.ac_voltage_L2 = el.ac_voltage_L2.toFixed(voltageFixed);
      el.ac_voltage_L3 = el.ac_voltage_L3.toFixed(voltageFixed);

      el.ac_active_power_L1 = el.ac_active_power_L1.toFixed(activeFixed);
      el.ac_active_power_L2 = el.ac_active_power_L2.toFixed(activeFixed);
      el.ac_active_power_L3 = el.ac_active_power_L3.toFixed(activeFixed);

      el.ac_reactive_power = el.ac_reactive_power.toFixed(powerFixed);
      el.ac_power_factor = el.ac_power_factor.toFixed(3);
      el.ac_frequency = el.ac_frequency.toFixed(2);

      el.dc_power_arr = el.dc_power.split(';');
      let dc_power_total = 0;
      el.dc_power_arr.forEach((e, i, a) => {
        let temp_value = parseFloat(e);
        a[i] = temp_value.toFixed(3);
        dc_power_total += temp_value;
      });
      el.dc_power_total = dc_power_total;
      el.dc_power_total_convert = wattConvertor(dc_power_total);


      el.dc_current_arr = el.dc_current.split(';');
      let dc_current_total = 0;
      el.dc_current_arr.forEach((e, i, a) => {
        let temp_value = parseFloat(e);
        a[i] = temp_value.toFixed(3);
        dc_current_total += temp_value;
      });
      el.dc_current_total = dc_current_total.toFixed(3);
      el.ac_total_energy_convert = wattConvertor(el.ac_total_energy, 'h');

      el.ac_active_power_total_convert = wattConvertor(el.ac_active_power_total);
      el.ac_daily_energy_convert = wattConvertor(el.ac_daily_energy, 'h');
      el.dc_voltage = el.dc_voltage;

    })
    state.inverterDatas = response;
  },
  [REFRESH_INVERTER_DATAS_MUTATION](state) {
    state.inverterDatas = [];
  },
  [REFRESH_DAILY_INVERTER_DATA_MUTATION](state) {
    state.dailyInverterData = 0;
  },
  [REFRESH_TOTAL_INVERTER_DATA_MUTATION](state) {
    state.totalInverterData = 0;
  },
  [SET_INVERTER_ALARMS](state, response) {
    response.forEach(item => {
      item.dateConvert = moment(item.date_record).format('DD.MM.YYYY HH:mm');
      item.timestamp = moment(item.date_record).format('X');
      item.info.forEach((infoItem,index) => {
        item.info[index] = infoItem.replaceAll('"', ' ');
      });
    });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
