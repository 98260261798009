import ApiService from "@/core/services/api.service";
import { wattConvertor } from '@/assets/js/functions/wattConvertor';

import moment from "moment/dist/moment";
import '@/assets/js/functions/moment.settings';

// action types

export const GET_AREAS = 'getAreas';
export const GET_AREA = 'getArea';
export const STORE_AREA = 'storeArea';
export const UPDATE_AREA = 'updateArea';
export const DELETE_AREA = 'deleteArea';
//
export const GET_AREA_DAILY_TOTAL = 'getAreaDailyTotal';
export const GET_AREA_TOTAL = 'getAreaTotal';
export const GET_AREA_INVERTER_DAILY_TOTAL = 'getAreaInverterDailyTotal';
export const GET_AREA_INVERTER_MONTHLY_TOTAL = 'getAreaInverterMonthlyTotal';
export const GET_AREA_INVERTER_DAILY = 'getAreaInverterDaily';
export const GET_AREA_INVERTER_MONTHLY = 'getAreaInverterMonthly';
export const RESET_AREA_CARD_DATAS = 'resetAreaCardDatas';
export const GET_STATION_FIELD_INVERTER_ALARMS = 'getStationFieldInverterAlarms';

// mutation types
export const SET_AREAS = 'setAreas';
export const SET_AREA = 'setArea';
export const SET_ERROR = "setErrorAreas";
//
export const SET_AREA_DAILY_TOTAL = 'setAreaDailyTotal';
export const SET_AREA_TOTAL = 'setAreaTotal';
export const SET_AREA_INVERTER_DAILY_TOTAL = 'setAreaInverterDailyTotal';
export const SET_AREA_INVERTER_MONTHLY_TOTAL = 'setAreaInverterMonthlyTotal';
export const SET_AREA_INVERTER_DAILY = 'setAreaInverterDaily';
export const SET_AREA_INVERTER_MONTHLY = 'setAreaInverterMonthly';

export const SET_AREA_TABLE_LOADING = "setAreaTableLoading";
//Deneme
export const RESET_AREA_CARD_DATAS_MUTATION = 'resetAreaCardDatas';
export const SET_STATION_FIELD_INVERTER_ALARMS = 'setStationFieldInverterAlarms';


const state = {
  errors: null,
  areaTableLoading: true,
  areas: [],
  area: [],
  getAreaDailyTotal: 0,
  getAreaTotal: 0,
  getAreaInverterDailyTotal: [],
  getAreaInverterMonthlyTotal: [],
  areaSeries: [],
  areaCategories: [],
  areaInverterDaily: [],
  areaInverterMonthly: [],
  areaInverterSeries: [],
  areaInverterCategories: [],
};

const getters = {
  getAreaTableLoading: state => state.areaTableLoading,
  getAreas: state => state.areas,
  getArea: state => state.area,
  getAreaDailyTotal: state => state.getAreaDailyTotal,
  getAreaTotal: state => state.getAreaTotal,
  getAreaInverterDailyTotal: state => state.getAreaInverterDailyTotal,
  getAreaInverterMonthlyTotal: state => state.getAreaInverterMonthlyTotal,
  getAreaSeries: state => state.areaSeries,
  getAreaCategories: state => state.areaCategories,
  getAreaInverterDaily: state => state.areaInverterDaily,
  getAreaInverterMonthly: state => state.areaInverterMonthly,
  getAreaInverterSeries: state => state.areaInverterSeries,
  getAreaInverterCategories: state => state.areaInverterCategories,
};

const actions = {
  [GET_AREAS](context) {
    return new Promise(resolve => {
      context.commit(SET_AREA_TABLE_LOADING, true);
      ApiService.get("ges/stationFields")
        .then(({ data }) => {
          context.commit(SET_AREAS, data.data);
          context.commit(SET_AREA_TABLE_LOADING, false);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [GET_AREA](context, payload) {
    return new Promise(resolve => {
      ApiService.get("ges/stationFields/" + payload)
        .then(({ data }) => {
          context.commit(SET_AREA, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [STORE_AREA](context, payload) {
    return new Promise(resolve => {
      ApiService.post("ges/stationFields", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    });
  },
  [UPDATE_AREA](context, payload) {
    return new Promise(resolve => {
      ApiService.put("ges/stationFields/" + payload.id, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, 'UPDATE_AREA-ERROR');

        });
    });
  },
  [DELETE_AREA](context, payload) {
    return new Promise(resolve => {
      ApiService.delete("ges/stationFields/" + payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, 'DELETE_AREA-ERROR');

        });
    });
  },
  [GET_AREA_DAILY_TOTAL](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      ApiService.get("ges/stationFieldDailyTotal", path)
        .then(({ data }) => {
          context.commit(SET_AREA_DAILY_TOTAL, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, 'GET_AREA_DAILY_TOTAL-ERROR');
        });
    });
  },
  [GET_AREA_TOTAL](context, payload) {
    return new Promise(resolve => {
      let path = payload.id;
      ApiService.get("ges/stationFieldTotal", path)
        .then(({ data }) => {
          context.commit(SET_AREA_TOTAL, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, 'GET_AREA_TOTAL-ERROR');

        });
    });
  },
  [GET_AREA_INVERTER_DAILY_TOTAL](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      ApiService.get("ges/stationFieldDailyTotal", path)
        .then(({ data }) => {
          context.commit(SET_AREA_INVERTER_DAILY_TOTAL, { response: data.data, begin_date: payload.begin_date });
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, 'GET_AREA_INVERTER_DAILY_TOTAL-ERROR');

        });
    });
  },
  [GET_AREA_INVERTER_MONTHLY_TOTAL](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      ApiService.get("ges/stationFieldMonthlyTotal", path)
        .then(({ data }) => {
          context.commit(SET_AREA_INVERTER_MONTHLY_TOTAL, { response: data.data, begin_date: payload.begin_date });
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, 'GET_AREA_INVERTER_MONTHLY_TOTAL-ERROR');

        });
    });
  },
  [GET_AREA_INVERTER_DAILY](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date + '&end_date=' + payload.begin_date;
      ApiService.get("ges/stationFieldDaily", path)
        .then(({ data }) => {
          context.commit(SET_AREA_INVERTER_DAILY, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, 'GET_AREA_INVERTER_DAILY-ERROR');
        });
    });
  },
  [GET_AREA_INVERTER_MONTHLY](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date;
      ApiService.get("ges/stationFieldMonthly", path)
        .then(({ data }) => {
          context.commit(SET_AREA_INVERTER_MONTHLY, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, 'GET_AREA_INVERTER_MONTHLY-ERROR');
        });
    });
  },
  [RESET_AREA_CARD_DATAS](context) {
    return new Promise(resolve => {
      context.commit(RESET_AREA_CARD_DATAS_MUTATION);
    });
  },
  //Sahaya Ait İnvertör Alarmlarını Getirir
  [GET_STATION_FIELD_INVERTER_ALARMS](context, payload) {
    return new Promise(resolve => {
      let path = payload.id + '?begin_date=' + payload.begin_date + '&end_date=' + payload.end_date;
      ApiService.get("ges/stationFieldsInverterAlarms", path)
        .then(({ data }) => {
          context.commit(SET_STATION_FIELD_INVERTER_ALARMS, data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        })
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    console.log(error);
    state.errors = error;
  },
  [SET_AREA_TABLE_LOADING](state, status) {
    state.areaTableLoading = status;
  },
  [SET_AREAS](state, response) {
    response.forEach(el => {
      el.dailyTotalConvert = wattConvertor(el.dailyTotal, 'h');
    })
    state.areas = response;
  },
  [SET_AREA](state, response) {
    response.invertersInfo.forEach(el => {
      if(el.last_data_time != null){
        el.dateConvert = moment(el.last_data_time).format('DD.MM.YYYY HH:mm');
        el.timestamp = moment(el.last_data_time).format('X');
      }
      else{
        el.dateConvert = '---';
        el.timestamp = 0;
      }
    });
    state.area = response.stationFieldsInfo;
  },
  [SET_AREA_DAILY_TOTAL](state, response) {
    if (response.length > 0) {
      let getAreaDailyTotal = false;
      response.forEach(el=>{
        let today = moment().format('YYYY-MM-DD');
        if(el.date_entry == today){
          state.getAreaDailyTotal = wattConvertor(el.total, 'h');
          getAreaDailyTotal = true;
        }
      })
      if(!getAreaDailyTotal){
        state.getAreaDailyTotal = wattConvertor(0, 'h');
      }
    }
    else {
      state.getAreaDailyTotal = wattConvertor(0, 'h');
    }
  },
  [SET_AREA_TOTAL](state, response) {
    state.getAreaTotal = wattConvertor(response, 'h');
  },
  [SET_AREA_INVERTER_DAILY_TOTAL](state, { response, begin_date }) {
    response.forEach(item => {
      item.totalConvert = wattConvertor(item.total, 'h');
      item.dateConvert = moment(item.date_entry).format('DD.MM.YYYY');
      item.timestamp = moment(item.date_entry).format('X');
      item.timestampx = moment(item.date_entry).format('x') * 1;
    });
    let j = 0;
    let max_day = 0;
    let current_year = moment().format('YYYY');
    let current_month = moment().format('MM');
    let response_year = moment(begin_date).format('YYYY');
    let response_month = moment(begin_date).format('MM');
    if (response_year == current_year) {//Getirilen veri yılı şimdiki yılsa doldurulacak maksimum günü belirlemek gerekir
      if (response_month == current_month) {//Eğer mevcut ay üzerinden veri getiriliyorsa bu güne kadar ki veriler dolurulacak
        max_day = moment().format('DD') * 1;
      }
      else {
        max_day = moment(begin_date).endOf('month').format('DD') * 1;
      }
    }
    else {//Getirilen veri yılı mevcut yıldan azsa doldurulacak maksimum gün o ayın maksimum günü kadardır
      max_day = moment(begin_date).endOf('month').format('DD') * 1;
    }
    let data_day = '';
    for (let i = 0; i < max_day; i++) {
      let temp_day = (i < 9) ? '0' + (i + 1) : '' + (i + 1);
      if (response[j]) {
        data_day = moment(response[j].date_entry).format('DD');
      }
      else {
        data_day = moment().format('DD');
      }
      if (temp_day != data_day) { //Eğer eksik değer varsa boş veri ekliyoruz
        let temp_date = response_year + '-' + response_month + '-' + temp_day;
        response.push({
          total: 0,
          totalConvert: '---',
          date_entry: temp_date,
          dateConvert: moment(temp_date).format('DD.MM.YYYY'),
          timestamp: moment(temp_date).format('X'),
          timestampx: moment(temp_date).format('x') * 1,
        });
      }
      else {
        j++;
      }
    }
    response.sort((a, b) => {
      let a_day = moment(a.date_entry).format('DD') * 1;
      let b_day = moment(b.date_entry).format('DD') * 1;
      return (a_day > b_day) ? 1 : -1;
    });
  },
  [SET_AREA_INVERTER_MONTHLY_TOTAL](state, { response, begin_date }) {
    response.forEach(item => {
      item.totalConvert = wattConvertor(item.total, 'h');
      item.dateConvert = moment(item.date_entry).format('MMMM YYYY');
      item.timestamp = moment(item.date_entry).format('X');
      item.timestampx = moment(item.date_entry).format('x') * 1;
    });
    response.reverse();
    let j = 0;
    let max_month = 0;
    let current_year = moment().format('YYYY');
    let response_year = moment(begin_date).format('YYYY');
    if (response_year == current_year) {//Getirilen veri yılı şimdiki yılsa doldurulacak maksimum ay mevcut aydır
      max_month = moment().format('MM') * 1;
    }
    else {//Getirilen veri yılı mevcut yıldan azsa doldurulacak maksimum ay 12 dir
      max_month = 12;
    }
    let data_month = '';
    for (let i = 0; i < max_month; i++) {
      let temp_month = (i < 9) ? '0' + (i + 1) : '' + (i + 1);
      if (response[j]) {
        data_month = moment(response[j].date_entry).format('MM');
      }
      else {
        data_month = moment().format('MM');
      }
      if (temp_month != data_month) { //Eğer eksik değer varsa boş veri ekliyoruz
        let temp_date = response_year + '-' + temp_month;
        response.push({
          total: 0,
          totalConvert: '---',
          date_entry: temp_date,
          dateConvert: moment(temp_date).format('MMMM YYYY'),
          timestamp: moment(temp_date).format('X'),
          timestampx: moment(temp_date).format('x') * 1,
        });
      }
      else {
        j++;
      }
    }
    response.sort((a, b) => {
      let a_month = moment(a.date_entry).format('MM') * 1;
      let b_month = moment(b.date_entry).format('MM') * 1;
      return (a_month > b_month) ? 1 : -1;
    });
  },
  [SET_AREA_INVERTER_DAILY](state, response) {
    state.areaInverterSeries = [];
    state.areaInverterCategories = [];
    response.forEach((el) => {
      el.export_convert = wattConvertor(el.export, 'h');
    });
    state.areaInverterDaily = response;
  },
  [SET_AREA_INVERTER_MONTHLY](state, response) {
    state.areaInverterSeries = [];
    state.areaInverterCategories = [];
    response.forEach((el) => {
      el.export_convert = wattConvertor(el.export, 'h');
    });
    state.areaInverterMonthly = response;
  },
  [RESET_AREA_CARD_DATAS_MUTATION]() {
    state.getAreaDailyTotal = 0;
    state.getAreaTotal = 0;
  },
  [SET_STATION_FIELD_INVERTER_ALARMS](state, response) {
    response.forEach(item => {
      item.dateConvert = moment(item.date_record).format('DD.MM.YYYY HH:mm');
      item.timestamp = moment(item.date_record).format('X');
      item.info.forEach((infoItem,index) => {
        item.info[index] = infoItem.replaceAll('"', ' ');
      });
    });
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
