// action types
import JwtService from "@/core/services/jwt.service";

export const GET_STATION_AUTHS = 'getStationAuths';
export const UPDATE_STATION_AUTH = 'updateStationAuth';
export const DELETE_STATION_AUTH = 'deleteStationAuth';

// mutation types
export const SET_STATION_AUTHS = 'setStationTypes';

export const SET_ERROR = "setError";
export const SET_TABLE_LOADING = "setTableLoading";

import ApiService from "@/core/services/api.service";

const state = {
    errors: null,
    stationAuths: [],
    stationNoAuths: [],
    tableLoading: true
};

const getters = {
    getStationAuths: state => state.stationAuths,
    getStationNoAuths: state => state.stationNoAuths,
    getTableLoading: state => state.tableLoading,
};

const actions = {
    [GET_STATION_AUTHS](context,id) {
        return new Promise(resolve => {
            context.commit(SET_TABLE_LOADING,true);
            ApiService.get("ges/powerStationsAuth/"+id)
                .then(({data}) => {
                    context.commit(SET_STATION_AUTHS, data.data);
                    context.commit(SET_TABLE_LOADING,false);
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response);
                });
        });
    },
    [UPDATE_STATION_AUTH](context, payload) {
        return new Promise(resolve => {
            context.commit(SET_TABLE_LOADING,true);
            ApiService.put("ges/powerStationsAuth/"+payload.id,payload)
                .then(({data}) => {
                    context.dispatch(GET_STATION_AUTHS, payload.id).then(res=>{
                        context.commit(SET_TABLE_LOADING,false);
                    });
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response);
                });
        });
    },
    [DELETE_STATION_AUTH](context, payload) {
        return new Promise(resolve => {
            context.commit(SET_TABLE_LOADING,true);
            ApiService.delete("ges/powerStationsAuth/"+payload.id+'?user_id='+payload.user_id)
                .then(({data}) => {
                    context.dispatch(GET_STATION_AUTHS, payload.id).then(res=>{
                        context.commit(SET_TABLE_LOADING,false);
                    });
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_TABLE_LOADING](state, status) {
        state.tableLoading = status;
    },
    [SET_STATION_AUTHS](state, response) {
        state.stationAuths = response.auth;
        state.stationNoAuths = response.no_auth;
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
