// action types
import ApiService from "@/core/services/api.service";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";

export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_API_RESPONSE = "setApiResponse";

const state = {
    user_personal_info: {},
    user_account_info: {},
    profileResponse:null
};

const getters = {
    currentUserPersonalInfo: state=>state.user_personal_info,
    currentUserAccountInfo: state=>state.user_account_info,
    currentUserPhoto: state=>state.user_personal_info.photo,
    getProfileResponse: state=> state.profileResponse
};

const actions = {
    [UPDATE_PERSONAL_INFO](context, payload) {
        return new Promise(resolve => {
            ApiService.put("updateProfile", payload).then(({data}) => {
                context.commit(VERIFY_AUTH);
                context.commit(SET_API_RESPONSE, data);
                resolve(data);
            }).catch(err => {
                context.commit(SET_API_RESPONSE, err.response.data);
            });
        });
    },
    [UPDATE_ACCOUNT_INFO](context, payload) {
        context.commit(SET_ACCOUNT_INFO, payload);
    }
};
// [UPDATE_PERSONAL_INFO](context, payload) {
//     context.commit(SET_PERSONAL_INFO, payload);
// },
const mutations = {
    [SET_PERSONAL_INFO](state, user_personal_info) {
        state.user_personal_info = user_personal_info;
    },
    [SET_ACCOUNT_INFO](state, user_account_info) {
        state.user_account_info = user_account_info;
    },
    [SET_API_RESPONSE](state, data) {
        let status = data.status;
        let code = data.code;
        let message = data.message;
        if (data.code == 400) {
            message = 'Formu doğru bir şekilde doldurun.';
        }
        state.profileResponse = {code, status, message};
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};
