import ApiService from "@/core/services/api.service";

//ACTIONS
export const GET_ALL_DEVICES = 'getAllDevices';
export const GET_DEVICE_LAST_DATAS_123 = 'getDeviceLastDatas123';//Sayaç/Röle/Analizör
export const GET_DEVICE_LAST_DATAS_45 = 'getDeviceLastDatas45';//Giriş/Çıkış Modülü

const actions = {
  //Tüm Cihazları Getirme
  [GET_ALL_DEVICES]() {
    return new Promise(resolve => {
      let path = "/devices";
      ApiService.get(path)
        .then(({ data }) => {
          resolve(data.data);
        })
    });
  },
  //Sayaç/Röle/Analizör İçin Son Veriler
  [GET_DEVICE_LAST_DATAS_123](context, payload) {
    return new Promise(resolve => {
      let path = payload.measuring_device_id;
      ApiService.get('/scada/scadaValues', path).then(({ data }) => {
        resolve(data);
      }).catch(({response})=>{
        let error = {
          message: 'Cihaz Bilgilerine Ulaşılamadı!'
        }
        resolve(error);
      });
    })
  },
  [GET_DEVICE_LAST_DATAS_45](context,payload){
    return new Promise(resolve => {
      let path = payload.measuring_device_id;
      ApiService.get('/io?measuring_device_id='+path).then(({ data }) => {
        resolve(data);
      }).catch(({response})=>{
        let error = {
          message: 'Cihaz Bilgilerine Ulaşılamadı!'
        }
        resolve(error);
      });
    })
  }
};

export default {
  actions
};
