import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
//
import location from "./location.module";
import stations from "./stations.module";
import areas from "./areas.module";
import stationTypes from "./stationTypes.module";
import stationAuth from "./stationAuth.module";
import inverters from "./inverters.module";
import inverterTypes from "./inverterTypes.module";
import devices from "./devices.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        htmlClass,
        config,
        breadcrumbs,
        profile,
        location,
        areas,
        stations,
        stationTypes,
        stationAuth,
        inverters,
        inverterTypes,
        devices
    }
});
