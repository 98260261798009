// action types
import JwtService from "@/core/services/jwt.service";

export const GET_INVERTER_TYPES = 'getInverterTypes';
export const GET_INVERTER_TYPE = 'getInverterType';
export const STORE_INVERTER_TYPE = 'storeInverterType';
export const UPDATE_INVERTER_TYPE = 'updateInverterType';
export const DELETE_INVERTER_TYPE = 'deleteInverterType';

// mutation types
export const SET_INVERTER_TYPES = 'setInverterTypes';
export const SET_INVERTER_TYPE = 'setInverterType';

export const SET_ERROR = "setError";
export const SET_INVERTER_TYPES_TABLE_LOADING = "setInverterTypesTableLoading";

import ApiService from "@/core/services/api.service";

const state = {
    errors: null,
    inverterTypesTableLoading: true,
    inverterTypes: [],
    inverterType: []
};

const getters = {
    getInverterTypesTableLoading: state => state.inverterTypesTableLoading,
    getInverterTypes: state => state.inverterTypes,
    getInverterType: state => state.inverterType,
};

const actions = {
    [GET_INVERTER_TYPES](context) {
        return new Promise(resolve => {
            context.commit(SET_INVERTER_TYPES_TABLE_LOADING,true);
            ApiService.get("ges/inverterTypes")
                .then(({data}) => {
                    context.commit(SET_INVERTER_TYPES, data.data);
                    resolve(data.data);
                    context.commit(SET_INVERTER_TYPES_TABLE_LOADING,false);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [GET_INVERTER_TYPE](context,payload) {
        return new Promise(resolve => {
            ApiService.get("ges/inverterTypes/"+payload)
                .then(({data}) => {
                    context.commit(SET_INVERTER_TYPE, data.data);
                    resolve(data.data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [STORE_INVERTER_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.post("ges/inverterTypes",payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [UPDATE_INVERTER_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.put("ges/inverterTypes/"+payload.id,payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [DELETE_INVERTER_TYPE](context, payload) {
        return new Promise(resolve => {
            ApiService.delete("ges/inverterTypes/"+payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_INVERTER_TYPES_TABLE_LOADING](state, status) {
        state.inverterTypesTableLoading = status;
    },
    [SET_INVERTER_TYPES](state, response) {
        state.inverterTypes = response;
    },
    [SET_INVERTER_TYPE](state, response) {
        state.inverterType = response;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
