import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_PERSONAL_INFO } from "@/core/services/store/profile.module";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setErrorAuth";
export const SET_API_RESPONSE = "setApiResponse";
export const SET_ROLE = "setRole";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  authResponse: {
    status: false,
    code: 200,
    message: 'Güvenli şifre oluşturun.'
  },
  userRole: null,
  buttonState: false
};

const getters = {
  currentUser: state => state.user,
  isAuthenticated: state => state.isAuthenticated,
  getErrors: state => state.errors,
  getAuthError: state => state.errors,
  getAuthResponse: state => state.authResponse,
  getUserRole: state => state.userRole,
  buttonState: state => state.buttonState
};

const actions = {
  [LOGIN](context, credentials) {
    context.commit('buttonState', true);
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.data);
          context.commit('buttonState', false);
          resolve(data.data);
        })
        .catch(error => {
          context.commit(SET_ERROR, error.response.data);
          context.commit('buttonState', false);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    window.location.href = '/login';
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("myDetails", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.message);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("/myDetails")
        .then(({ data }) => {
          context.commit(SET_PERSONAL_INFO, data.data);
          context.commit(SET_ROLE, data.data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    return new Promise(resolve => {
      ApiService.put("securityProfile", payload).then(({ data }) => {
        context.commit(SET_API_RESPONSE, data);
        resolve(data);
      }).catch(err => {
        context.commit(SET_ERROR, err.response.data);
        context.commit(SET_API_RESPONSE, err.response.data);
      });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    let status = error.status;
    let code = error.code;
    let message = error.message;
    state.errors = { code, status, message };
  },
  [SET_API_RESPONSE](state, data) {
    let status = data.status;
    let code = data.code;
    let message = data.message;
    if (data.code == 400) {
      message = 'Formu doğru bir şekilde doldurun.';
    }
    state.authResponse = { code, status, message };
  },
  [SET_AUTH](state, response) {
    state.isAuthenticated = true;
    JwtService.saveToken(response.token.access_token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_ROLE](state, response) {
    state.userRole = response.role;
  },
  buttonState(state, response) {
    state.buttonState = response;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
