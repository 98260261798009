// action types
import JwtService from "@/core/services/jwt.service";

// export const GET_CITY = 'getCity';
// export const GET_COUNTY = 'getCounty';
export const GET_CITIES = 'getCities';
export const GET_COUNTIES = 'getCounties';

// mutation types
// export const SET_CITY = 'setCity';
// export const SET_COUNTY = 'setCounty';
export const SET_CITIES = 'setCities';
export const SET_COUNTIES = 'setCounties';
export const SET_ERROR = "setError";

import ApiService from "@/core/services/api.service";

const state = {
    errors: null,
    // city: null,
    // county: null,
    cities: [],
    counties: [],
};

const getters = {
    // getCity: state => state.city,
    // getCounty: state => state.county,
    getCities: state => state.cities,
    getCounties: state => state.counties,
};

const actions = {
    [GET_CITIES](context) {
        return new Promise(resolve => {
            ApiService.get("listCity")
                .then(({data}) => {
                    context.commit(SET_CITIES, data.data);
                    resolve(data.data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [GET_COUNTIES](context) {
        return new Promise(resolve => {
            ApiService.get("listCounty")
                .then(({data}) => {
                    context.commit(SET_COUNTIES, data.data);
                    resolve(data.data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },

};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error;
    },
    [SET_CITIES](state, response) {
        state.cities = response;
    },
    [SET_COUNTIES](state, response) {
        state.counties = response;
    },

};

export default {
    state,
    actions,
    mutations,
    getters
};
